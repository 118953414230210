
import { Header, Image, Message, Container } from "semantic-ui-react";



const Thankyou = () => {
  console.log('RENDER Thankyou')
    

  return (

<div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <Container textAlign="center">
      <Header as='h2' color='blue' textAlign='center'>
          <Image src='/images/logo.png' /> 
        </Header>
        <Message>
          <Message.Header>Thank you for using the Adthisway platform</Message.Header>
          <p>Your data has been deleted and your account has been deactivated</p>
        </Message>
      </Container>
    </div>


  );
};

export default Thankyou;