import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState} from "react";
import { Button, Message, Icon, Modal, Header, Input} from "semantic-ui-react";
import { useHistory } from "react-router-dom";




const DeleteModal = (props) => {
  console.log('RENDER DeleteModal')

  const user = props.user
  const setLoader = props.setLoader

  const history = useHistory();
 
  const [opendelete, setOpendelete] = useState()
  const [disablebutton, setDisablebutton] = useState(true)
  const [deletefail, setDeletefail] = useState(false)


  
  return   (
  
        <Modal 
                  closeIcon
                  open={opendelete}
                  trigger={<Button type='button' style={{marginLeft:'30em'}} color='red'>Delete My Account</Button>}
                  onClose={() => setOpendelete(false)}
                  onOpen={() => setOpendelete(true)}>
                
                <Header icon='warning' content='Delete My Account' />

                <Modal.Content>
                  <p> 
                    Are you sure you want to delete your account ? <br/>
                    Your profile and published needs/offers will be deleted permanently.
                  </p>
                  <p>Please type your email address to confirm:</p>

                 {deletefail && 
                  <Message negative>
                      <Message.Header>Something went wrong</Message.Header>
                      <p>We couldn't complete your request. Please try again later.</p>
                    </Message>                  
                  }

                  <Input
                        fluid
                        placeholder='Your email...'
                        onChange={(e) => {
                          if(user.username === e.target.value) 
                            setDisablebutton(false) 
                          else 
                            setDisablebutton(true)
                        }}
                      />             
                </Modal.Content>
                
                <Modal.Actions>
                  <Button color='red' onClick={() => setOpendelete(false)}>
                    <Icon name='remove' /> No
                  </Button>

                  <Button color='green' 
                          disabled={disablebutton}
                          onClick={() => {
                                           setLoader(true)
                                           setDeletefail(false)
                                           setOpendelete(false)
                                          userService.deleteMyAccount().then(()=> {
                                            setLoader(false)
                                            authService.logout();
                                            history.push("/thankyou");
                                            window.location.reload();
                                          })
                                          .catch(e => {
                                            setOpendelete(true)
                                            setLoader(false)
                                            setDeletefail(true)
                                          })

                                          }}>
                    <Icon name='checkmark' /> Yes
                  </Button>
                </Modal.Actions>
              </Modal>        
  
    ) 
  

  };

export default DeleteModal;