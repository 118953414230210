import { useState, useEffect } from "react";
import { Switch, Route} from 'react-router-dom'

import { Container, List, Segment,  Image } from "semantic-ui-react";

import Navbar from './components/misc/Navbar'
import Login from "./components/login/Login";
import Thankyou from "./components/login/Thankyou";
import Register from "./components/register/Register";
import ProfileForm from "./components/myprofile/ProfileForm";
import MyPackages from "./components/mypackages/MyPackages";
import Error500 from "./components/error/Error500";
import Customer from "./components/profilecard/Customer";
import PublishForm from "./components/publishmy/PublishForm";
import PublishDetails from "./components/mypackages/needs_offers/PublishDetails";
import PublishesByPackage from "./components/mypackages/needs_offers/PublishesByPackage";
import './App.css'
import Home from "./components/home/Home";
import {authService} from "./services/auth.service";
import EditPublishForm from "./components/publishmy/EditPublishForm";
import Contact from "./components/contact/Contact";
import Forgot from "./components/forgot/Forgot";




function App() {
  console.log("RENDER App")

  const [currentUser, setCurrentUser] = useState(undefined);


  useEffect(() => {
    const user = authService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
    } 
  }, []);



  return (
    <div>
      <div className="content-container"> 
    
      <Navbar currentuser={currentUser}/>

      <Container fluid style={{ marginTop: '7em' }}>
      
        <Switch>
            <Route exact path={["/", "/home"]}> <Home /> </Route>
            
            <Route exact path="/login"> <Login setCurrentUser={setCurrentUser} /> </Route>
            <Route exact path="/thankyou"> <Thankyou /> </Route>            
            <Route exact path="/register"> <Register /> </Route>

            <Route exact path="/customers/:id"> <Customer/> </Route>
            <Route exact path="/profile"> <ProfileForm /> </Route>

            <Route exact path="/packages"> <MyPackages /> </Route>          
            <Route exact path="/packages/:id/publish"> <PublishesByPackage /> </Route>
            

            <Route exact path="/packages/:pid/mypublish/:nid"> <PublishDetails /> </Route>
            <Route exact path="/packages/:pid/mypublish/:nid/edit"> <EditPublishForm /> </Route>
            <Route path="/publish"> <PublishForm /> </Route>

            <Route path="/contact"> <Contact /> </Route>

            <Route  path="/error500"> <Error500 /> </Route>

            <Route  path="/forgot"> <Forgot /> </Route>
            {/* <Route> <Error404 /> </Route> */}
         </Switch>

      </Container>
      </div>

      <footer className="footer--pin">
        <Segment inverted vertical style={{ marginTop: '5em 0em 0em', padding: '1em 0em' }}>
          <Container textAlign='center'>
            <Image centered size='mini' src='images/logo.png' />
            <List horizontal inverted divided link size='small'>
              <List.Item as='a' href='https://adthisway.com/terms' target='_blank' rel='noopener noreferrer'>
                Terms and Conditions
              </List.Item>
              <List.Item as='a' href='https://adthisway.com/privacy' target='_blank' rel='noopener noreferrer'>
                Privacy Policy
              </List.Item>
            </List>
          </Container>
        </Segment>
      </footer>
    </div>
  );
}

export default App;
