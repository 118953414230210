
import axios from "axios";
import authHeader from "./auth-header";
import {config} from '../Config'



const updateCustomerProfile = (profilerequest) => {
  return instance.post("/customers/profile", profilerequest, { headers : authHeader() });
};







const createOffer = (needrequest, packageid) => {
  return instance.post(`/mypackages/${packageid}/myoffers`, needrequest, { headers : authHeader() });
};

const createMultipartOffer = ( formdata, packageid) => {
  return instance.post(`/mypackages/${packageid}/multipartoffers`, formdata, { headers : authHeader() });
};


const updateMultipartOffer = (formdata, offerid) => {
  return instance.put(`/mypackages/multipartoffers/${offerid}`, formdata, { headers : authHeader() });
};


const updateOffer = (needrequest, offerid) => {
  return instance.put(`/mypackages/myoffers/${offerid}`, needrequest, { headers : authHeader() });
};



const createNeed = (needrequest, packageid) => {
  return instance.post(`/mypackages/${packageid}/myneeds`, needrequest, { headers : authHeader() });
};



const updateNeed = (needrequest, needid) => {
  return instance.put(`/mypackages/myneeds/${needid}`, needrequest, { headers : authHeader() });
};


const checkServiceExistsInOffers = (serviceid) => {
  return instance.get(`/serviceexists/${serviceid}`, { headers : authHeader() });
};



const getContinents = () => {
  return instance.get("/continents", { headers : authHeader() });
};



const deleteMyNeed = (packageid, needid) => {
  return instance.delete(`/mypackages/${packageid}/myneeds/${needid}`, { headers : authHeader() });
};


const deleteMyOffer = (packageid, needid) => {
  return instance.delete(`/mypackages/${packageid}/myoffers/${needid}`, { headers : authHeader() });
};


const deleteMyAccount = () => {
  return instance.delete(`/user`, { headers : authHeader() });
};


const getCountries = () => {
  return instance.get(`/continents/countries`, { headers : authHeader() });
};


const getRegions = (id) => {
  return instance.get(`/countries/${id}/regions`, { headers : authHeader() });
};


const getCustomerProfile = () => {
  return instance.get("/myprofile", { headers : authHeader() });
};



const getCustomerPackages = () => {
  return instance.get("/mypackages", { headers : authHeader() });
};


const getCustomerPackagesIds = () => {
  return instance.get("/mypackagesids", { headers : authHeader() });
};



const getMyPackage = (id) => {
  return instance.get(`/mypackages/${id}`, { headers : authHeader() });
};


const getMyPackageNeeds = (id) => {
  return instance.get(`/mypackages/${id}/publishes`, { headers : authHeader() });
};


const getMyNeed = (id) => {
  return instance.get(`/mypublishes/${id}`, { headers : authHeader() });
};



const getMyMatches = (id) => {
  return instance.get(`/mymatches/${id}`, { headers : authHeader() });
};


const getOrgOptions = () => {
  return instance.get(`/customertypes`, { headers : authHeader() });
};

const getTalentOptions = () => {
  return instance.get(`/talenttypes`, { headers : authHeader() });
};

const getOrgOptionsSpec = (id) => {
  return instance.get(`/organisationoptions/${id}/specs`, { headers : authHeader() });
};



const getContractorServicesOptions = () => {
  return instance.get(`/contractors/services`, { headers : authHeader() });
};




const getPromotions = ( orgtype, orgspec) => {
  if(orgtype)
    return instance.get(`/promotions`, { headers : authHeader(), params : {customertypeid: orgtype, customertypespecid: orgspec} });
  else
    return instance.get(`/promotions`, { headers : authHeader(), params : { customertypespecid: orgspec} });
};


const getPromotionTools = (id, orgtype, orgspec, promotion) => {
  return instance.get(`/packages/${id}/promotions/${promotion}/tools`, { headers : authHeader(), params : {orgtype: orgtype, orgspec: orgspec} });
};


const getPromotionToolSpecs = (id, orgtype, orgspec, promotion, tool) => {
  return instance.get(`/packages/${id}/promotions/${promotion}/tools/${tool}/specs`, { headers : authHeader(), params : {orgtype: orgtype, orgspec: orgspec} });
};



const getCustomer = (id) => {
  return instance.get(`/customers/${id}`, { headers : authHeader() });
};


const getIndustries = () => {
  return instance.get(`/industries`, { headers : authHeader() });
};

const getCompetitionTypes = () => {
  return instance.get(`/competitions`, { headers : authHeader() });
};

const getMusicTypes = () => {
  return instance.get(`/musictypes`, { headers : authHeader() });
};


const getShowTypes = () => {
  return instance.get(`/showtypes`, { headers : authHeader() });
};






const getProfileStatus = () => {
  console.log('http call')

  return instance.get(`/profile/status`, { headers : authHeader() });
};


const uploadLogoFile = ( formdata) => {
  return instance.post(`/uploadlogo`, formdata, { headers : authHeader() });
};



const uploadFacilityFile = ( formdata) => {
  return instance.post(`/uploadfacility`, formdata, { headers : authHeader() });
};


const sendMessage = ( formdata) => {
  return instance.post(`/contact`, formdata, { headers : authHeader() });
};


const instance = axios.create({
    baseURL: config.url.API_BASE_URL
})


export const userService = {

  getCustomerPackagesIds,
  getProfileStatus,
  getIndustries,
  getCompetitionTypes,
  getMusicTypes,
  getShowTypes,
  getContractorServicesOptions,
  getCustomer,
  getPromotions,
  getPromotionTools,
  getPromotionToolSpecs,


  deleteMyNeed,
  deleteMyOffer,

  deleteMyAccount,

  getOrgOptions,
  getTalentOptions,
  getOrgOptionsSpec,
  getCustomerProfile,
  getCustomerPackages,
  getMyPackage,
  getMyPackageNeeds,
  getMyNeed,
  getMyMatches,
  getContinents,
  getCountries,
  getRegions,

  updateCustomerProfile,

  uploadLogoFile,
  uploadFacilityFile,

  createNeed,
  updateNeed,
  
  createOffer,
  updateOffer,

  createMultipartOffer,
  updateMultipartOffer,  

  checkServiceExistsInOffers,

  sendMessage

};