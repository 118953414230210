const dev = {
    url : {
        API_BASE_URL: 'http://localhost:8088'        
    }    
}


let win =  process.env.REACT_APP_URL
console.log(`App version: ${process.env.REACT_APP_VERSION}`);

console.log(win)

let url;
if(process.env.NODE_ENV === 'development')
    url = dev;
else
    url = {url :{API_BASE_URL: process.env.REACT_APP_URL}}

export const config =  url
