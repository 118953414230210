import  { useState } from "react";
import { Form, Segment, Label, Dimmer, Loader } from "semantic-ui-react";
import ProfileLogoForm from "./LogoForm";
import {userService} from "../../../services/user.service";
import {DateUtils} from "../../../services/Utils";


const SegmentOverview = (props) => {
  console.log('RENDER SegmentOverviewForm')

  const profile = props.profile
  const user = props.user
  const dispatch = props.dispatch;
  const photo = props.photo

  const errorname = props.errorname
  const setErrorname = props.setErrorname

  
  const errorlogo = props.errorlogo
  const setErrorlogo = props.setErrorlogo

  const errorindustry = props.errorindustry
  const setErrorindustry = props.setErrorindustry
  const errorservice = props.errorservice
  const setErrorservice = props.setErrorservice

  const errorcountry = props.errorcountry
  const setErrorcountry = props.setErrorcountry  

  const errorregion = props.errorregion
  const setErrorregion = props.setErrorregion  

  const errorcity = props.errorcity
  const setErrorcity = props.setErrorcity    

  const errorceo = props.errorceo
  const setErrorceo = props.setErrorceo


  const errorbirthdate = props.errorbirthdate
  const setErrorbirthdate = props.setErrorbirthdate

  const errorestablishment = props.errorestablishment
  const setErrorestablishment = props.setErrorestablishment


  const errorachievements = props.errorachievements
  const setErrorachievements = props.setErrorachievements

  const errorabout = props.errorabout
  const setErrorabout = props.setErrorabout
  

  const errorclientele = props.errorclientele

  const industries = props.industries

  const services = props.services
  // const setIndustries = props.setIndustries
  const countries = props.countries
  // const setCountries = props.setCountries
  const regions = props.regions
  const setRegions = props.setRegions







  const onChangeCountry = (e, data) => {
    userService.getRegions(data.value).then(response => { setRegions(response.data)})
    setErrorcountry(undefined)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  };




  const onChangeInput = (event, data) => {
    // let regex = new RegExp("^[\-\={}\\[\\]`~;:\\\\|<>/\"a-zA-Z0-9\r?\n$@$!%*?’&#(),'^-_. +]+$");

    // console.log('ev', event)
    // console.log('data', data)
    let contractor = false

    if(data.name === 'name' ){
      if(data.value.length > 40){
        setErrorname({content: 'Name is more than 40 characters long', pointing: 'below'})
        return
      } 

      if(data.value.length === 0){
        setErrorname({content: 'Name cannot be empty', pointing: 'below'})
        // setSegmentoverviewerror(true)
        return
      }

      if(data.value.length > 0 && ! DateUtils.NO_GREEK_REGEX.test(data.value)){ 
        setErrorname({content: 'English only', pointing: 'below'})
        // setSegmentoverviewerror(true)
        return 
      }

      setErrorname(undefined)
      // setSegmentoverviewerror(false)
    }


    if(data.name === 'city' ){
      if(data.value.length > 40){
        setErrorcity({content: 'City is more than 40 characters long', pointing: 'below'})
        return
      } 

      if(data.value.length === 0){
        setErrorcity({content: 'City cannot be empty', pointing: 'below'})
        return
      }

      if(data.value.length > 0 && ! DateUtils.NO_GREEK_REGEX.test(data.value)){ 
        setErrorcity({content: 'English only', pointing: 'below'})
        return
      }

      setErrorcity(undefined)
    }
    
    

    if(data.name === 'ceo' ){
      if(data.value.length > 100){
        setErrorceo({content: 'CEO is more than 100 characters long', pointing: 'below'})
        return
      } 

      setErrorceo(undefined)
    }    

    
    if(data.name === 'establishmentdate'){
      if(data.value.length !== 4){
        setErrorestablishment({content: 'Not valid format', pointing: 'below'})
        return        
      }

      setErrorestablishment(undefined)      
    }

    if(data.name === 'birthdate' ){
      if(data.value.length === 0){
        setErrorbirthdate({content: 'Date cannot be empty', pointing: 'below'})
        return
      }

      if( data.value.length >= 10 && !DateUtils.dateIsValid(data.value)){
        setErrorbirthdate({content: 'Not valid formatbb', pointing: 'below'})
        return        
      }

      setErrorbirthdate(undefined)
    }        


    if(data.name === 'achievements' && user && user.customerparenttype === 'TALENT'){
      if(data.value.length === 0){
        setErrorachievements({content: 'City cannot be empty', pointing: 'below'})
        return
      }

      if(data.value.split(' ').length > 100){
        setErrorachievements({content: 'Max 100 words allowed', pointing: 'below'})
        return
      } 


      if(data.value.length > 0 && ! DateUtils.NO_GREEK_REGEX.test(data.value)){ 
        setErrorachievements({content: 'English only', pointing: 'below'})
        return
      }           

      setErrorachievements(undefined)
    }            


    if(data.name === 'history' ){
      if(data.value.split(' ').length > 100){
        setErrorabout({content: 'Max 100 words allowed', pointing: 'below'})
        return
      } 


      if(data.value.length > 0 && ! DateUtils.NO_GREEK_REGEX.test(data.value)){ 
        setErrorabout({content: 'English only', pointing: 'below'})
        return
      }     
      
      if(data.value.length===0){
        setErrorabout({content: 'Cannot be empty', pointing: 'below'})
        return
      }      

      setErrorabout(undefined)
    }    



    if(data.name === 'industry'){
      if(data.value.length===0){
        setErrorindustry({content: 'Cannot be empty', pointing: 'below'})
        return
      } 

      setErrorindustry(undefined)
    }    


    if(data.name === 'service'){
      if(event.type === 'blur'){
        return
      }

      if(data.value.length===0){
        setErrorservice({content: 'Cannot be empty', pointing: 'below'})
        return
      }
      
      const exists = profile.service.some(obj => obj.value === data.value);
      if(exists) return

      setErrorservice(undefined)
      // console.log('e',event.target.innerText)
      // console.log('data',data.value)
      profile.service.push({value:data.value, text: event.target.innerText} )
      dispatch({type:"UPDATE_STATE", name: 'service', value: profile.service});
      contractor = true
    }        


    if(data.name === 'region'){
      if(data.value != null){
        setErrorregion(undefined)
      }
    }
    
    if(!contractor)
    dispatch({type:"UPDATE_STATE", name: data.name, value: data.value});
  }


  // console.log('pppp', profile)
  const [checkloader, setCheckloader] =useState()


  return  profile && (
    <Segment raised>
        <Label style={{marginBottom:'1em'}} as='a' color='red' ribbon>
          Overview
        </Label>      

        <Form.Input 
          label='Name' 
          name='name'
          error={errorname}
          required
          defaultValue={profile.name} 
          placeholder='Company Name'
          onChange={onChangeInput} 
        />


        <Form.Input 
          label='TAX ID' 
          name='taxid'
          defaultValue={profile.taxid} 
          readOnly={true}
        />        


      {/* {user && user.customertype == 'TALENT' && sports.length > 0 ? 
        (
        <Form.Select 
          placeholder='Select your sport' 
          required 
          label='Sport' 
          value={profile.continent} 
          options={sports}  
          name='continent'
          onChange={onChangeInput} 
        />
        ) : (<></>)
      } */}


      <ProfileLogoForm 
        photo={photo}
        profile={profile}
        errorlogo={errorlogo}
        setErrorlogo={setErrorlogo}
        dispatch={dispatch}
      />


      {user && user.customertype === 'COMPANY' && industries.length > 0 ? 
        <Form.Select 
          placeholder='Select Industry' 
          required 
          error={errorindustry}
          multiple
          label='Industry' 
          defaultValue={profile.industry} 
          options={industries}  
          name='industry'
          onChange={onChangeInput} 
        />
      :<></>
      }


      {user && user.customertype === 'CONTRACTOR' && services.length > 0 &&
        <>
          <Form.Select 
            placeholder='Select Offerred services' 
            required 
            // multiple
            label='Services' 
            error={errorservice}
            // defaultValue={profile.service} 
            value={null}
            options={services}  
            name='service'
            onChange={onChangeInput} 
          />
          <div>{profile.service.map(s => 
            <a  class="ui label" 
                style={{fontSize:'1em'}}
                value={s.value}> {s.text} <i onClick={() => { 
                  setCheckloader(true)        
                  userService.checkServiceExistsInOffers(s.value).then(resp => {
                    setCheckloader(false)
                    const serviceIsUsed = resp.data
                    if(!serviceIsUsed){
                      dispatch({type:"UPDATE_STATE", name: 'service', value: profile.service.filter(ser => ser.value !== s.value)});
                    } else {
                      alert('This service can not be removed as it is already published via packages. Delete offer(s) containing this service first')
                    }
                  })
                } } aria-hidden="true" class="delete icon"></i>
            </a>)}

          {checkloader && <Dimmer active inverted>
            <Loader>Checking if service is used...</Loader>
          </Dimmer>
          }

          </div>
        </>
        
      }      


    <div style={{marginBottom:'1em', marginTop:'2em'}}>
    
      <Form.Select 
        required
        placeholder='Select your country'
        label='Country' 
        value={profile.country}
        options={countries} 
        name='country'
        error={errorcountry}
        onChange={onChangeCountry}  
      />

      <Form.Select 
        required
        placeholder='Select your region'
        label='Region' 
        value={profile.region}
        options={regions}  
        name='region'
        error={errorregion}
        onChange={onChangeInput} 
      />


      <Form.Input className='required'
        label='City'
        defaultValue={profile.city} 
        placeholder='City' 
        name='city'
        error={ errorcity}
        onChange={onChangeInput} 
        
      />

    </div>

    {user && user.customerparenttype !== 'TALENT'?
      <div style={{marginBottom:'1em'}} >
        <Form.Input 
          label='CEO'
          defaultValue={profile.ceo} 
          placeholder='CEO'
          name='ceo'
          error={ errorceo}
          onChange={onChangeInput} 
        />
      </div>
      :<></>}

      {user && (user.customerparenttype === 'CULTURE' || user.customerparenttype === 'MEDIA' || user.customerparenttype === 'SPORT') ?
      <Form.Input 
          label='Year of establishment'
          defaultValue={profile.establishmentdate} 
          placeholder='YYYY'
          name='establishmentdate'
          error={ errorestablishment}
          onChange={onChangeInput} 
        />
        :<></>}

      {user && user.customerparenttype === 'TALENT'  ?
      <Form.Input className='required'
          label='Date of birth'
          defaultValue={profile.birthdate} 
          placeholder='DD-MM-YYYY'
          name='birthdate'
          error={ errorbirthdate}
          onChange={onChangeInput} 
        />
        :<></>}


        <Form.TextArea className='required'
          label={user && user.customerparenttype === 'TALENT'?'Biography':'About'}
          defaultValue={profile.history} 
          placeholder='Short description'
          name='history'
          error={ errorabout}
          onChange={onChangeInput} 
        />

        
        {user && (user.customertype === 'CONTRACTOR' || user.customertype === 'MUSIC' || user.customerparenttype === 'MEDIA' || user.customerparenttype === 'SPORT') ?
        <Form.TextArea 
          label='Achievements'
          defaultValue={profile.achievements} 
          placeholder='Short description'
          name='achievements'
          // error={errorcontrachievements}
          onChange={onChangeInput} 
        />
        :<></>}

        {user && user.customerparenttype === 'TALENT'  ?
        <Form.TextArea className='required'
          label='Achievements'
          defaultValue={profile.achievements} 
          placeholder='Short description'
          name='achievements'
          error={errorachievements}
          onChange={onChangeInput} 
        />
        :<></>}        


        {user && user.customertype === 'CONTRACTOR' ?
        <Form.TextArea 
          label='Clientele'
          defaultValue={profile.clientelle} 
          placeholder='Short description'
          name='clientelle'
          error={ errorclientele}
          onChange={onChangeInput} 
        />     
        :<></>}                           

    </Segment>
    ) 
  };

export default SegmentOverview;