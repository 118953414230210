import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState, useEffect, useReducer } from "react";
import { Container, Form, Button, Message, Dimmer, Loader } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import SegmentOverview from "./modules/SegmentOverview";
import SegmentFacility from "./modules/SegmentFacility";
import SegmentAudience from "./modules/SegmentAudience";
import SegmentProduct from "./modules/SegmentProduct";
import SegmentSocial from "./modules/SegmentSocial";
import SegmentContact from "./modules/SegmentContact";
import DeleteModal from "./DeleteModal";


const FETCH_STATE = "FETCH_STATE"
const UPDATE_STATE = "UPDATE_STATE"

const formReducer = (profile, event) => {

  switch (event.type) {
    case FETCH_STATE:
      return event.payload;

    case UPDATE_STATE:
      return { ...profile, [event.name]: event.value }

    case 'UPDATE_PRODUCT': {
      const myevents = event.target
      return {...profile, myevents}
    }    

    default:
      return profile;
  }  
 }

 const initialState = {schedevents:{}};


const ProfileOrgCultureForm = () => {
  console.log('RENDER ProfileOrgCultureForm')

  const [profile, dispatch] = useReducer(formReducer, initialState);
  const [user, setUser] = useState()
  const [loader, setLoader] = useState(false)
  const [profileloader, setProfileloader] = useState(false)

  const [countries, setCountries] = useState([])
  const [regions, setRegions] = useState([])
  const [myevents, setMyevents] = useState([])
  const history = useHistory();
  const [segmentoverviewerror,setSegmentoverviewerror]=useState()


  const [errorname, setErrorname] = useState()
  const [errorlogo, setErrorlogo] = useState()
  const [errorcountry, setErrorcountry] = useState(false)
  const [errorregion, setErrorregion] = useState(false)
  const [errorcity, setErrorcity] = useState()
  const [errorceo, setErrorceo] = useState()
  const [errorabout, setErrorabout] = useState(false)
  const [errorestablishment, setErrorestablishment] = useState()
  
  const [errorfixed, setErrorfixed] = useState()
  const [errormobile, setErrormobile] = useState()
  const [erroremail, setErroremail] = useState()

  const [errorfacilityphoto, setErrorfacilityphoto] = useState()

  const [success, setSuccess] = useState(false)
  
  const [errorcontact, setErrorcontact] = useState()
  const [errormembersnumber, setErrormembersnumber] = useState()
  const [errorfacilityname, setErrorfacilityname] = useState()
  const [errorfacilitycapacity, setErrorfacilitycapacity] = useState()
  const [errorfacilityholders, setErrorfacilityholders] = useState()  
  const [erroraudiencemembers, setErroraudiencemembers] = useState()
  const [errortvbroadcasting, setErrortvbroadcasting] = useState()
  const [errortvbroadcastingnumber, setErrortvbroadcastingnumber] = useState()
  const [errorradiobroadcasting, setErrorradiobroadcasting] = useState()
  const [errornewscoverage, setErrornewscoverage] = useState()
  const [errormagazinescoverage, setErrormagazinescoverage] = useState()
  const [errorwebcoverage, setErrorwebcoverage] = useState()
  const [errorschedule, setErrorschedule] = useState()
  const [erroreventsnumber, setErroreventsnumber] = useState()
  const [errorscheduleonline, setErrorscheduleonline] = useState()
  const [errorschedulemanual, setErrorschedulemanual] = useState()


  
  const handleSubmit = (e) => {
    e.preventDefault();

    const notemptyError = {content: 'Cannot be empty', pointing: 'below'}

    let errors = false


    //**** OVERVIEW ******
    if(profile.logo==null){
      setErrorlogo(notemptyError)
      errors = true
    }

    if(profile.country==null){
      setErrorcountry(notemptyError)
      errors = true
    }    

    if(profile.region==null){
      setErrorregion(notemptyError)
      errors = true
    }        


    if(profile.city==null){
      setErrorcity(notemptyError)
      errors = true
    }            

    if(profile.establishmentdate != null && profile.establishmentdate.length !== 4){
      setErrorestablishment({content: 'Not valid format', pointing: 'below'})
      errors = true
    }    


    if(profile.history==null){
      setErrorabout(notemptyError)
      errors = true
    }                


    //******* FACILITY */
    if(profile.facilityname==null){
      setErrorfacilityname(notemptyError)
      errors = true
    }                


    if(profile.facilitycapacity==null){
      setErrorfacilitycapacity(notemptyError)
      errors = true
    }                

    if(profile.facilityholders==null){
      setErrorfacilityholders(notemptyError)
      errors = true
    }            
    
    if(profile.facilityphoto==null){
      setErrorfacilityphoto(notemptyError)
      errors = true
    }
    

    //******** AUDIENCE */
    if(profile.members==null){
      setErroraudiencemembers(notemptyError)
      errors = true
    }
    
    if(profile.tvbroadcasting==null){
      setErrortvbroadcasting(notemptyError)
      errors = true
    }    

    if(profile.radiobroadcasting==null){
      setErrorradiobroadcasting(notemptyError)
      errors = true
    }    

    if(profile.newspaperscoverage==null){
      setErrornewscoverage(notemptyError)
      errors = true
    }    


    if(profile.magazinescoverage==null){
      setErrormagazinescoverage(notemptyError)
      errors = true
    }
    
    if(profile.webcoverage==null){
      setErrorwebcoverage(notemptyError)
      errors = true
    }    


    //******** PRODUCT */
    if(profile.schedule==null){
      setErrorschedule(notemptyError)
      errors = true
    }                

    if(profile.eventsnumber==null){
      setErroreventsnumber(notemptyError)
      errors = true
    }                    


    if(profile.schedule==='online' && (profile.scheduleonline==null || profile.scheduleonline==='')){
      setErrorscheduleonline(notemptyError)
      errors = true
    }                    

    if(profile.schedule==='manual' && (profile.myevents==null || profile.myevents.length === 0)){
      setErrorschedulemanual(notemptyError)
      errors = true
    }                        


    //******** CONTACT */
    if(profile.contactperson==null){
      setErrorcontact(notemptyError)
      errors = true
    }                
    
    if(profile.contactemail==null){
      setErroremail(notemptyError)
      errors = true
    }                
    
    if(profile.contactfixednumber==null){
      setErrorfixed(notemptyError)
      errors = true
    }                
    
    if(profile.contactmobilenumber==null){
      setErrormobile(notemptyError)
      errors = true
    }                    

    if(errors){
      return
    }        

    setLoader(true)
    userService.updateCustomerProfile(profile).then(()=> {
      setLoader(false)
      setSuccess(true)
    })
    .catch(error => {
      // console.log(error.response)
            history.push("/error500");
    })    

  };


  const haserrors = () => segmentoverviewerror ||
                          errorname || errorlogo || errorabout || errorcountry || errorregion || errorceo || errorcity || errorestablishment ||
                          errorfacilitycapacity || errorfacilityholders || errorfacilityname || errorfacilityphoto ||
                          erroraudiencemembers || errormembersnumber || errortvbroadcasting || errortvbroadcastingnumber || errorradiobroadcasting || errornewscoverage || errormagazinescoverage || errorwebcoverage ||
                          errorschedule || erroreventsnumber || errorscheduleonline || errorschedulemanual ||
                          errorcontact || errorfixed || errormobile || erroremail    




useEffect(() => {
  setProfileloader(true)
  const myuser = authService.getCurrentUser();
  setUser(myuser)

  if (myuser) {
    userService.getCustomerProfile().then((response) => {
      
      const myprofile = response.data;

      if(myprofile.myevents){
        let x = myprofile.myevents.map((ev,index) => { return {'name':ev} })
        myprofile.myevents = x;
        setMyevents(myprofile.myevents)
      }

      dispatch({ type:FETCH_STATE, payload: response.data });


      userService.getCountries().then((response)=> {
        setCountries(response.data)
        if(myprofile.country)
        userService.getRegions(myprofile.country).then((response)=> setRegions(response.data))          
      });

    })
    .catch(error => {
      // console.log(error.response)
            history.push("/error500");
    })    
    .finally(()=>{
      setProfileloader(false)
    })
  }
}, []);


  // console.log(profile)
  return  (
    <Container style={{ paddingBottom: '5em' }} text>

    {profileloader &&
      <Dimmer active inverted>
        <Loader inverted>Loading profile</Loader>
      </Dimmer>
      }

      {profile.customerid &&  
      <Form 
        onSubmit={handleSubmit} 
        loading={loader || !profile.customerid}
        success={success}
        error={haserrors()}        
        >


      <SegmentOverview
        profile={profile}
        user={user}        
        countries={countries}
        regions={regions}
        setRegions={setRegions}
        dispatch={dispatch}

        segmentoverviewerror={segmentoverviewerror}
        setSegmentoverviewerror={setSegmentoverviewerror}
        errorname={errorname}
        setErrorname={setErrorname}        
        errorlogo={errorlogo}
        setErrorlogo={setErrorlogo}
        errorcountry={errorcountry}
        setErrorcountry={setErrorcountry}
        errorregion={errorregion}
        setErrorregion={setErrorregion}
        errorcity={errorcity}
        setErrorcity={setErrorcity}
        errorceo={errorceo}
        setErrorceo={setErrorceo}
        errorabout={errorabout}
        setErrorabout={setErrorabout}   
        errorestablishment={errorestablishment}
        setErrorestablishment={setErrorestablishment}              
      />


      <SegmentFacility
        profile={profile}
        user={user}        
        dispatch={dispatch}

        errorfacilityname={errorfacilityname}
        setErrorfacilityname={setErrorfacilityname}
        errorfacilitycapacity={errorfacilitycapacity}
        setErrorfacilitycapacity={setErrorfacilitycapacity}  
        errorfacilityholders={errorfacilityholders}
        setErrorfacilityholders={setErrorfacilityholders}
        errorfacilityphoto={errorfacilityphoto}
        setErrorfacilityphoto={setErrorfacilityphoto}
      />

      <SegmentAudience 
        profile={profile}
        user={user}        
        dispatch={dispatch}

        errormembersnumber={errormembersnumber}
        setErrormembersnumber={setErrormembersnumber}        

        errortvbroadcastingnumber={errortvbroadcastingnumber}
        setErrortvbroadcastingnumber={setErrortvbroadcastingnumber}

        erroraudiencemembers={erroraudiencemembers}
        setErroraudiencemembers={setErroraudiencemembers}

        errortvbroadcasting={errortvbroadcasting}
        setErrortvbroadcasting={setErrortvbroadcasting}

        errorradiobroadcasting={errorradiobroadcasting}
        setErrorradiobroadcasting={setErrorradiobroadcasting}

        errornewscoverage={errornewscoverage}
        setErrornewscoverage={setErrornewscoverage}

        errormagazinescoverage={errormagazinescoverage}
        setErrormagazinescoverage={setErrormagazinescoverage}

        errorwebcoverage={errorwebcoverage}
        setErrorwebcoverage={setErrorwebcoverage}
      />



      <SegmentProduct 
        profile={profile}
        user={user}        
        dispatch={dispatch}

        myevents={myevents}
        setMyevents={setMyevents}
        productType={'Events'}
        
        errorschedule={errorschedule}
        setErrorschedule={setErrorschedule}          

        erroreventsnumber={erroreventsnumber}
        setErroreventsnumber={setErroreventsnumber}

        errorscheduleonline={errorscheduleonline}
        setErrorscheduleonline={setErrorscheduleonline}
        
        errorschedulemanual={errorschedulemanual}
        setErrorschedulemanual={setErrorschedulemanual}        
      />




      <SegmentSocial 
        profile={profile}
        dispatch={dispatch}        
      />

      <SegmentContact 
        profile={profile}
        dispatch={dispatch}    

        errorcontact={errorcontact}
        setErrorcontact={setErrorcontact}
        erroremail={erroremail}
        setErroremail={setErroremail}
        errorfixed={errorfixed}
        setErrorfixed={setErrorfixed}
        errormobile={errormobile}
        setErrormobile={setErrormobile}
      />



        <Message
          success
          header='Profile saved successfully.'
          content="Go to 'My Needs' to publish your needs!"
        />        

        <Message
           error
           icon='warning'
           header='Cannot save form!'
           content='Form contains errors'
        />


        <Button type='submit' disabled={haserrors()}> Save </Button>

        <DeleteModal user={user} setLoader={setLoader} />

      </Form>
    }
    
    </Container>
    )
  

  };

export default ProfileOrgCultureForm;