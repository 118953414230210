import {authService} from "../../services/auth.service";
import {userService} from "../../services/user.service";
import  { useState, useEffect, useReducer } from "react";
import { Container, Form, Button, Message, Segment, Label, Dimmer, Loader,  } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import DeleteModal from "./DeleteModal";


import SegmentOverview from "./modules/SegmentOverview";
import SegmentProduct from "./modules/SegmentProduct";
import SegmentSocial from "./modules/SegmentSocial";
import SegmentContact from "./modules/SegmentContact";


const FETCH_STATE = "FETCH_STATE"
const UPDATE_STATE = "UPDATE_STATE"

const formReducer = (profile, event) => {

  switch (event.type) {
    case FETCH_STATE:
      return event.payload;

    case UPDATE_STATE:
      return { ...profile, [event.name]: event.value }

    case 'UPDATE_PRODUCT': {
      const myevents = event.target
      return {...profile, myevents}
    }    
        
    default:
      return profile;
  }  

 }

 const initialState = {};


const ProfileOrgMediaForm  = () => {
  console.log('RENDER ProfileOrgMediaForm ')
  
  const [profile, dispatch] = useReducer(formReducer, initialState);
  const [profileloader, setProfileloader] = useState(false)
  const [user, setUser] = useState()
  const [loader, setLoader] = useState(false)
  const [countries, setCountries] = useState([])
  const [regions, setRegions] = useState([])

  const [errorlogo, setErrorlogo] = useState()
  const [errorcountry, setErrorcountry] = useState(false)
  const [errorregion, setErrorregion] = useState(false)
  const [errorabout, setErrorabout] = useState(false)

  const [errorname, setErrorname] = useState()
  const [errorcity, setErrorcity] = useState()
  const [errorestablishment, setErrorestablishment] = useState()
  const [errorceo, setErrorceo]= useState()


  const [erroraudience1, setErroraudience1] = useState()
  const [erroraudience, setErroraudience] = useState()
  const [erroraudience2, setErroraudience2] = useState()

  const [errorcontact, setErrorcontact] = useState()
  const [errorfixed, setErrorfixed] = useState()
  const [errormobile, setErrormobile] = useState()
  const [erroremail, setErroremail] = useState()

  const [success, setSuccess] = useState(false)
  const history = useHistory();

  const [audience1, setAudience1] = useState()
  const [audience2, setAudience2] = useState()

  const [myevents, setMyevents] = useState([])
  const [errorschedule, setErrorschedule] = useState()
  const [errorscheduleonline, setErrorscheduleonline] = useState()
  const [errorschedulemanual, setErrorschedulemanual] = useState()



  const handleSubmit = (e) => {
    e.preventDefault();

    const notemptyError = {content: 'Cannot be empty', pointing: 'below'}
    let errors = false

    //**** OVERVIEW ******
    if(profile.logo==null){
      setErrorlogo(notemptyError)
      errors = true
    }

    if(profile.country==null){
      setErrorcountry(notemptyError)
      errors = true
    }    

    if(profile.region==null){
      setErrorregion(notemptyError)
      errors = true
    }        


    if(profile.city==null){
      setErrorcity(notemptyError)
      errors = true
    }            

    if(profile.establishmentdate != null && profile.establishmentdate.length !== 4){
      setErrorestablishment({content: 'Not valid format', pointing: 'below'})
      errors = true
    }    


    if(profile.history==null){
      setErrorabout(notemptyError)
      errors = true
    }                

    //**** OVERVIEW ******
    if(profile.audience==null){
      setErroraudience(notemptyError)
      errors = true
    }                
    
    if(profile.audience1==null){
      setErroraudience1(notemptyError)
      errors = true
    }                
    
    if(profile.audience2==null){
      setErroraudience2(notemptyError)
      errors = true
    }                  
    
    
    //******** PRODUCT */
    if(profile.schedule==null){
      setErrorschedule(notemptyError)
      errors = true
    }                

    if(profile.schedule==='online' && (profile.scheduleonline==null || profile.scheduleonline==='')){
      setErrorscheduleonline(notemptyError)
      errors = true
    }                    

    //console.log(profile)
    if(profile.schedule==='manual' && (profile.myevents==null || profile.myevents.length === 0)){
      setErrorschedulemanual(notemptyError)
      errors = true
    }                        


    //******** CONTACT */
    if(profile.contactperson==null){
      setErrorcontact(notemptyError)
      errors = true
    }                
    
    if(profile.contactemail==null){
      setErroremail(notemptyError)
      errors = true
    }                
    
    if(profile.contactfixednumber==null){
      setErrorfixed(notemptyError)
      errors = true
    }                
    
    if(profile.contactmobilenumber==null){
      setErrormobile(notemptyError)
      errors = true
    }                        

    if(errors){
      return
    }        


    setLoader(true)
    userService.updateCustomerProfile(profile).then(()=> {
      setLoader(false)
      setSuccess(true)
    })
    .catch(error => {
      // console.log(error.response)
            history.push("/error500");
    })    

  };


  const onChangeInput = (event, data) => {

    // let regex = new RegExp("^[a-zA-Z0-9\r?\n$@$!%*?&#(),'^-_. +]+$");
    let regex = new RegExp("^[-={}\\[\\]`~;:\\\\|<>/\"a-zA-Z0-9\r?\n$@$!%*?&#(),'^-_. +]+$");

    if(data.name === 'audience' ){
      if(data.value.split(' ').length > 100){
        setErroraudience({content: 'Max 100 words allowed', pointing: 'below'})
        return
      } 


      if(data.value.length > 0 && !regex.test(data.value)){ 
        setErroraudience({content: 'English only', pointing: 'below'})
        return
      }     
      
      if(data.value.length===0){
        setErroraudience({content: 'Cannot be empty', pointing: 'below'})
        return
      }      

      setErroraudience(undefined)
    }
    
    
    if(data.name === 'audience1' ){
      if(data.value.split(' ').length > 100){
        setErroraudience1({content: 'Max 100 words allowed', pointing: 'below'})
        return
      } 


      if(data.value.length > 0 && !regex.test(data.value)){ 
        setErroraudience1({content: 'English only', pointing: 'below'})
        return
      }     
      
      if(data.value.length===0){
        setErroraudience1({content: 'Cannot be empty', pointing: 'below'})
        return
      }      

      setErroraudience1(undefined)
    }    


    if(data.name === 'audience2' ){
      if(data.value.split(' ').length > 100){
        setErroraudience2({content: 'Max 100 words allowed', pointing: 'below'})
        return
      } 


      if(data.value.length > 0 && !regex.test(data.value)){ 
        setErroraudience2({content: 'English only', pointing: 'below'})
        return
      }     
      
      if(data.value.length===0){
        setErroraudience2({content: 'Cannot be empty', pointing: 'below'})
        return
      }      

      setErroraudience2(undefined)
    }    

    dispatch({type:UPDATE_STATE, name: data.name, value: data.value});
  }



  useEffect(() => {
    // setFetchloader(true)
    setProfileloader(true)
    const myuser = authService.getCurrentUser();
    setUser(myuser)
  
    if (myuser) {

      if(myuser.customertype === 'TV' || myuser.customertype === 'RADIO'){
        setAudience1('Ratings')
      }

      if(myuser.customertype === 'Newspaper'.toUpperCase() || myuser.customertype === 'Magazine'.toUpperCase()){
        setAudience1('Circulation')
      }

      if(myuser.customertype === 'Website'.toUpperCase() ){
        setAudience1('Analytics')
      }

      if(myuser.customertype === 'TV' || myuser.customertype === 'Website'.toUpperCase() ){
        setAudience2('Markets (countries)')
      }

      if(myuser.customertype === 'Radio'.toUpperCase() || myuser.customertype === 'Newspaper'.toUpperCase() || myuser.customertype === 'Magazine'.toUpperCase()){
        setAudience2('Country regions')
      }


      userService.getCustomerProfile().then((response) => {
        
        const myprofile = response.data;
  
        if(myprofile.myevents){
          let x = myprofile.myevents.map((ev,index) => { return {'name':ev} })
          myprofile.myevents = x;
          setMyevents(myprofile.myevents)
        }
  
        dispatch({ type:FETCH_STATE, payload: response.data });
  
  
        userService.getCountries().then((response)=> {
          setCountries(response.data)
          if(myprofile.country)
          userService.getRegions(myprofile.country).then((response)=> setRegions(response.data))          
        });
      })
      .catch(error => {
        // console.log(error.response)
              history.push("/error500");
      })      
      .finally(()=>{  setProfileloader(false)})
    }
  
  }, []);
  


  const haserrors = () => errorname || errorlogo || errorabout || errorcountry || errorregion || errorcity || errorestablishment ||
                          erroraudience || erroraudience1 || erroraudience2 ||
                          errorschedule || errorscheduleonline || errorschedulemanual ||
                          errorcontact || errorfixed || errormobile || erroremail    

  

  return   (
    <Container style={{ paddingBottom: '5em' }} text>

      {profileloader &&
        <Dimmer active inverted>
          <Loader inverted>Loading profile</Loader>
        </Dimmer>
        }

      {profile.customerid &&  
      <Form 
        onSubmit={handleSubmit} 
        loading={loader || !profile.customerid}
        success={success}
        error={haserrors()}        
        >

      <SegmentOverview
        profile={profile}
        user={user}        
        countries={countries}
        regions={regions}
        setRegions={setRegions}
        dispatch={dispatch}

        errorname={errorname}
        setErrorname={setErrorname}        
        errorlogo={errorlogo}
        setErrorlogo={setErrorlogo}
        errorcountry={errorcountry}
        setErrorcountry={setErrorcountry}
        errorregion={errorregion}
        setErrorregion={setErrorregion}
        errorcity={errorcity}
        setErrorcity={setErrorcity}
        errorabout={errorabout}
        setErrorabout={setErrorabout}         
        errorestablishment={errorestablishment}
        setErrorestablishment={setErrorestablishment}  
        errorceo={errorceo}
        setErrorceo={setErrorceo}                            
      />



      <Segment raised>

        <Label as='a' color='violet' ribbon>
            Audience
        </Label>                

        <Form.TextArea className='required'
          label={audience1}
          defaultValue={profile.audience1} 
          name='audience1'
          error={ erroraudience1}
          onChange={onChangeInput} 
        />

        <Form.TextArea className='required'
          label='Audience'
          defaultValue={profile.audience} 
          placeholder='Short description'
          name='audience'
          error={ erroraudience}
          onChange={onChangeInput} 
        />        


        <Form.TextArea className='required'
          label={audience2}
          defaultValue={profile.audience2} 
          name='audience2'
          error={ erroraudience2}
          onChange={onChangeInput} 
        />                
      </Segment>
        


      <SegmentProduct
        productType={'Program'} 
        profile={profile}
        user={user}        
        dispatch={dispatch}

        myevents={myevents}
        setMyevents={setMyevents}
        
        errorschedule={errorschedule}
        setErrorschedule={setErrorschedule}                                        
        errorscheduleonline={errorscheduleonline}
        setErrorscheduleonline={setErrorscheduleonline}
        errorschedulemanual={errorschedulemanual}
        setErrorschedulemanual={setErrorschedulemanual}        
      />



      <SegmentSocial 
        profile={profile}
        dispatch={dispatch}        
      />



      <SegmentContact 
        profile={profile}
        dispatch={dispatch}    

        errorcontact={errorcontact}
        setErrorcontact={setErrorcontact}
        erroremail={erroremail}
        setErroremail={setErroremail}
        errorfixed={errorfixed}
        setErrorfixed={setErrorfixed}
        errormobile={errormobile}
        setErrormobile={setErrormobile}
      />



        <Message
          success
          header='Profile saved successfully.'
          content="Go to 'My Needs' to publish your needs!"
        />        

        <Message
           error
           icon='warning'
           header='Cannot save form!'
           content='Form contains errors'
        />

      <Button type='submit' disabled={haserrors()}> Save </Button>

      <DeleteModal user={user} setLoader={setLoader} />

      </Form>
      }
    
    </Container>
    ) 
  

  };

export default ProfileOrgMediaForm ;